import React from 'react'
import './stylesheets/Signup.scss'
import {Input, SelectPicker, Divider, DatePicker, Button, Checkbox, InputNumber} from 'rsuite'
import {toast} from 'react-toastify'
import axios from 'axios'
import {CheckPicker} from 'rsuite'

class Signup extends React.Component{

    state = {

        facultatea : null,
        facultatea2 : "",
        ambeleFac : false,
        optionList : [],
        optionList2 : [],
        rural : false,
        casatorie : "",
        social: false,
        rrom: false,
        sesiunea : "",
        initiala : "",
        localitatea : "",
        cetatenie : "",
        domiciliu : "",
        telefonFix : "",
        telefonMobil :"",
        medieBAC : "",
        medieEdFizica : "",
        medieBACRO : "",
        psiho : false,
        studii_univ : "",
        declar : false,
        gdpr : false,
        submitedInfo : ""
    

    }

    componentDidMount = async () => {

        let submitedInfo = await axios.get('/api/submission/getInfo/' +  localStorage.getItem('id'))
        console.log(submitedInfo.data !== "")

        if ( submitedInfo.data !== "" ){
            this.setState({
                submitedInfo : submitedInfo.data,
                facultatea : submitedInfo.data.facultatea,
                facultatea2 : "",
                ambeleFac :submitedInfo.data.ambeleFac,
                optionList : submitedInfo.data.optionList,
                optionList2 : submitedInfo.data.optionList2,
                rural : submitedInfo.data.rural,
                casatorie : submitedInfo.data.casatorie,
                social: submitedInfo.data.social,
                rrom: submitedInfo.data.rrom,
                sesiunea : submitedInfo.data.sesiunea,
                initiala : submitedInfo.data.initiala,
                localitatea : submitedInfo.data.localitatea,
                cetatenie : submitedInfo.data.cetatenie,
                domiciliu : submitedInfo.data.domiciliu,
                telefonFix : submitedInfo.data.telefonFix,
                telefonMobil :submitedInfo.data.telefonMobil,
                medieBAC : submitedInfo.data.medieBAC,
                medieEdFizica : submitedInfo.data.medieEdFizica,
                medieBACRO : submitedInfo.data.medieBACRO,
                psiho : submitedInfo.data.psiho,
                studii_univ : submitedInfo.data.studii_univ,
                declar : submitedInfo.data.declar,
                gdpr : submitedInfo.data.gdpr,
            })
        }
        
    }

    handleChange = (value, event) => {
        this.setState({
            [event.target.name] : value
        })
    }

    handleSelectFacultate = (value) => {

        // if ( this.state.facultatea !== null) document.getElementsByClassName("rs-picker-toggle-clean")[1].click()

        this.setState({
            facultatea : value,
            optionList : []
        })
    }

    handleSelectSesiune = (value) => {

        this.setState({
            sesiunea : value
        })
    }
    handleSelectProba = (value) => {

        this.setState({
            proba : value
        })
    }

    sendData = () => {


        console.log(this.state)
        if ( this.state.telefonMobil.length !== 10 ){
            toast.error("Numarul de telefon nu este corect scris")
            return;
        }

        

        if ( this.state.declar === false || this.state.gdpr === false ){
            toast.error("Nu ati bifat casutele de termeni si conditii si declaratie pe proprie raspundere")
            return;
        }

        if ( this.state.facultatea === "" || this.state.sesiunea === "" || this.state.initiala === "" ||
        this.state.localitatea === "" || this.state.domiciliu === "" || this.state.cetatenie === "" 
        || this.state.telefonMobil === "" || this.state.optionList.length === 0 || this.state.medieBAC === "" ){

            toast.error("Nu ati completat toate campurile");
            return;
        }

        
        let obj = {
            userID : localStorage.getItem('id'),
            facultatea : this.state.facultatea,
            facultatea2 : this.state.facultatea2,
            ambeleFac : this.state.ambeleFac,
            optionList : this.state.optionList,
            optionList2 : this.state.optionList2,
            casatorie : this.state.casatorie,
            rural : this.state.rural,
            social : this.state.social,
            rrom : this.state.rrom,
            sesiunea : this.state.sesiunea,
            initiala : this.state.initiala,
            localitatea : this.state.localitatea,
            domiciliu : this.state.domiciliu,
            cetatenie : this.state.cetatenie,
            telefonFix : this.state.telefonMobil,
            telefonMobil : this.state.telefonMobil,
            medieBAC : this.state.medieBAC.toString(),
            medieEdFizica : this.state.medieEdFizica.toString(),
            medieBACRO : this.state.medieBACRO.toString(),
            studii_univ : this.state.studii_univ,
            type : "Master",
            psiho : this.state.psiho
        }

        
        if ( this.state.submitedInfo !== "" ){
            axios.post('/api/submission/editInfo/' + localStorage.getItem('id'), obj)
            .then( response => {
    
                toast.success("Informatii salvate cu succes!")
                // this.props.moveToDocuments();
                window.location.reload();
                console.log(response);
            })
            .catch ( err => {
                toast.error(err.message)
            })
        }
        else{
            axios.post('/api/submission/submitInfo/' + localStorage.getItem('id'), obj)
        .then( response => {

            toast.success("Informatii salvate cu succes!")
            // this.props.moveToDocuments();
            window.location.reload();

            console.log(response)
        })
        .catch ( err => {
            toast.error("S-a produs o eroare")
        })
        }
        // axios.post('/api/submission/submitInfo/' + localStorage.getItem('id'), obj)
        
    }

    selectOption = (value, item) => {

        console.log(item);
        let options = this.state.optionList
        let selectedItem = item.value;

        if ( options.includes(selectedItem) === true ){

            let indexEl = options.indexOf(selectedItem);
            if (indexEl > -1) {
                options.splice(indexEl, 1);

                this.setState({
                    optionList : options
                }, () => {
                    console.log(options)
                })
            }
            
        }
        else{
            options.push(selectedItem);
            this.setState({
                optionList : options
            }, () => {
                console.log(options)
            })
        }
    }


    clearOptions = () => {


            this.setState({
                optionList : []
            }, () => {
                alert("da");
            })
    }

    // selectOption2 = (value, item) => {

    //     console.log(item);
    //     let options = this.state.optionList2
    //     options.push(value);
    //         this.setState({
    //             optionList2 : options[options.length - 1]
    //         }, () => {
    //             console.log(options)
    //         })
    // }

    selectAmbele = (value, checked) => {

        this.setState({
            ambeleFac : checked
        })
    }
    render(){

        let options = this.state.optionList.map( (e, index) => {

            return <p> {index + 1}. {e}</p>
        })

        let options2 = this.state.optionList2.map( (e, index) => {

            return <p> {index + 1}. {e}</p>
        })
        
        let data = []
        let data2 = []

        if ( this.state.facultatea === "Kinetoterapie"){
           data = [
            {
                "label": "Nutritie si Remodelare Corporala",
                "value": "Nutritie si Remodelare Corporala",
                "role": "Master"
            },
            {
                 "label": "Recuperare-Reeducare Motrica si Somato-Functionala",
                 "value": "Recuperare-Reeducare Motrica si Somato-Functionala",
                 "role": "Master"
             },
        ]

            if ( this.state.rrom === true ){
                data = [
                    {
                    "label": "Recuperare-Reeducare Motrica si Somato-Functionala",
                    "value": "Recuperare-Reeducare Motrica si Somato-Functionala",
                    "role": "Master"
                }
            ]
            }

           
        }

        if ( this.state.facultatea2 === "Kinetoterapie"){
            data2 = [{
                 "label": "Kinetoterapie si motricitate speciala",
                 "value": "Kinetoterapie si motricitate speciala",
                 "role": "Master"
             },
             {
                "label": "Recuperare-Reeducare Motrica si Somato-Functionala",
                "value": "Recuperare-Reeducare Motrica si Somato-Functionala",
                "role": "Master"
            }
        ]

             if ( this.state.rrom === true ){
                data2 = []
            }
            
         }

        if ( this.state.facultatea === "Educatie Fizica si Sport"){

            data = [
                
                {
                    "label": "Educatie Fizica si Activitati Motrice de Timp Liber",
                    "value": "Educatie Fizica si Activitati Motrice de Timp Liber",
                    "role": "Master"
                },
                {
                    "label": "Educatie Fizica si Activitati Motrice de Timp Liber",
                    "value": "Educatie Fizica si Activitati Motrice de Timp Liber",
                    "role": "Master"
                },
                {
                    "label": "Management si Marketing in Structurile, Activitatile si Evenimentele Sportive",
                    "value": "Management si Marketing in Structurile, Activitatile si Evenimentele Sportive",
                    "role": "Master"
                },
                {
                    "label": "Management si Marketing in Structurile, Activitatile si Evenimentele Sportive",
                    "value": "Management si Marketing in Structurile, Activitatile si Evenimentele Sportive",
                    "role": "Master"
                },
                {
                    "label": "Performanta in Sport",
                    "value": "Performanta in Sport",
                    "role": "Master"
                },
                {
                    "label": "Performanta in Sport",
                    "value": "Performanta in Sport",
                    "role": "Master"
                },
               
                
            ]

            if ( this.state.rrom === true){
                data = [
                
                    {
                        "label": "Management si Marketing in Structurile, Activitatile si Evenimentele Sportive",
                        "value": "Management si Marketing in Structurile, Activitatile si Evenimentele Sportive",
                        "role": "Master"
                    },]

            }

           
           
        }

        // if ( this.state.facultatea2 === "Educatie Fizica si Sport"){
        //     data2 = [
                
        //         {
        //             "label": "Educatie Fizica si Sportiva",
        //             "value": "Educatie Fizica si Sportiva",
        //             "role": "Master"
        //         },
        //         {
        //             "label": "Sport si Performanta Motrica",
        //             "value": "Sport si Performanta Motrica",
        //             "role": "Master"
        //         },
        //         {
        //             "label": "Management in Sport",
        //             "value": "Management in Sport",
        //             "role": "Master"
        //         },
        //         {
        //             "label": "Sport si Performanta Motrica - IFR",
        //             "value": "Sport si Performanta Motrica - IFR",
        //             "role": "Master"
        //         },
        //         {
        //             "label": "Educatie Fizica si Sportiva",
        //             "value": "Educatie Fizica si Sportiva",
        //             "role": "Master"
        //         },
        //         {
        //             "label": "Sport si Performanta Motrica",
        //             "value": "Sport si Performanta Motrica",
        //             "role": "Master"
        //         },
        //         {
        //             "label": "Management in Sport",
        //             "value": "Management in Sport",
        //             "role": "Master"
        //         },
        //         {
        //             "label": "Educatie Fizica si Sportiva - Limba Franceza - cu taxa",
        //             "value": "Educatie Fizica si Sportiva - Limba Franceza - cu taxa",
        //             "role": "Master"
        //         },
        //         {
        //             "label": "Educatie Fizica si Sportiva - Limba Engleza - cu taxa",
        //             "value": "Educatie Fizica si Sportiva - Limba Engleza - cu taxa",
        //             "role": "Master"
        //         },
                
        //     ]

        //     if ( this.state.rrom === true){
        //         data2 = [
                
        //             {
        //                 "label": "Educatie Fizica si Sportiva",
        //                 "value": "Educatie Fizica si Sportiva",
        //                 "role": "Master"
        //             }]

        //     }
 
        // }

       

        
        return(

            <div className = "Signup-container">
                <div className = "Signup-content">
                    <h1> Fisa de Inscriere </h1>
                    <h6> <a href = "http://admitere.unefs.ro" target = "_blank"> Link catre Brosurile de Admitere </a></h6>
                    <Divider />


                    {/* <div className = "Input-element" >

                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.rural} onChange = {(value, checked) => this.setState({rural : checked, social : false, rrom: false })}> Aplic pe locurile alocate absolventilor din mediul rural </Checkbox>
                    </div>

                    <div className = "Input-element" >

                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.social} onChange = {(value, checked) => this.setState({social : checked, rural : false, rrom : false })}> Aplic pe locurile alocate absolventilor de protectie sociala </Checkbox>
                    </div> */}

                    <div className = "Input-element" >

                        <Checkbox  style = {{fontSize: "17px"}} checked = {this.state.rrom} onChange = {(value, checked) => this.setState({rrom : checked, rural : false, social : false})}> Aplic pe locurile alocate absolventilor de etnie rroma (locuri disponibile doar la Programele de Studii Educatie Fizica si Sport, Sport si Performanta Motrica si Kinetoterapie si Motricitate Speciala) </Checkbox>
                    </div>


                    <div className = "Input-element" >
                        <p> Facultatea </p>
                        <SelectPicker value = {this.state.facultatea} onSelect = {this.handleSelectFacultate} data = {[{label: "Kinetoterapie", value : "Kinetoterapie"}
                    ]} id = "input" block searchable = {false} placeholder = "Alege o Optiune" />
                    </div>

                    {this.state.facultatea !== null? <div style = {{display: "flex", flexDirection:"row", justifyContent: "space-between"}}>
                        <div>
                            <p> Program de Studii</p>
                            <CheckPicker value = {this.state.optionList} id = "hello" searchable = {false} onClean = {() => this.setState({optionList: []})} onSelect = {this.selectOption} data={data} style={{ width: 400}} />
                        </div>
                        <div>
                            <p> Lista optiuni in ordinea preferintei </p>
                            {options}
                        </div>
                    </div>:null}


                    {/* <div className = "Input-element" >

                        <Checkbox style = {{fontSize: "17px"}} onChange = {this.selectAmbele}> Aplic la ambele facultati </Checkbox>
                    </div>


                    {this.state.ambeleFac === true?<div className = "Input-element" >
                        <p> Facultatea a doua </p>
                        <SelectPicker onSelect = {(value) => this.setState({facultatea2 : value})} data = { this.state.facultatea === "Kinetoterapie"? [{label: "Educatie Fizica si Sport", value : "Educatie Fizica si Sport"}]:[{label: "Kinetoterapie", value : "Kinetoterapie"}]}
                        id = "input" block searchable = {false} placeholder = "Alege o Optiune" />
                    </div>:null} */}
                    
                  

                   

                    {/* {this.state.facultatea2 !== null && this.state.ambeleFac === true? <div style = {{display: "flex", flexDirection:"row", justifyContent: "space-between", marginTop: "40px"}}>
                        <div>
                            <p> Program de Studii - Faculatea a doua </p>
                            <CheckPicker id = "hello" searchable = {false} onClean = {() => this.setState({optionList2: []})} onSelect = {this.selectOption2} data={data2} style={{ width: 400}} />
                        </div>
                        <div>
                            <p> Lista optiuni in ordinea preferintei </p>
                            {options2}
                        </div>
                    </div>:null}
                     */}
                   
                   
                    {/* {this.state.facultatea !== null?<div className = "Input-element" >
                        <p> Program de Studii</p>


                        <SelectPicker
                        onSelect = {this.handleSelectProba} 
                        data = {(this.state.facultatea === "Kinetoterapie")?[
                            {label: "Kinetoterapie si motricitate speciala", value : "Kinetoterapie si motricitate speciala"},
                    
                        ]: []}
                        id = "input"  block searchable = {false} placeholder = "Alege o Optiune"/> 
                    </div>: null} */}

                    <div className = "Input-element" >
                        <p> Sesiunea </p>
                        <SelectPicker value = {this.state.sesiunea}  onSelect = {this.handleSelectSesiune} data = {[{label: "Septembrie 2024", value : "Septembrie 2024"}]} id = "input" block searchable = {false} placeholder = "Alege o Optiune" />
                    </div>

                    <div className = "Input-element" >
                        <p> Initiala Tatalui </p>
                        <Input value = {this.state.initiala}  onChange = {this.handleChange} name = "initiala" className = "input" />
                    </div>

                    <div className = "Input-element" >
                        <p> Numele din inainte de casatorie (daca este cazul) </p>
                        <Input value = {this.state.casatorie}  onChange = {this.handleChange} name = "casatorie" className = "input" />
                    </div>
                   
                    <div className = "Input-element" >
                        <p> Localitatea </p>
                        <Input value = {this.state.localitatea}  onChange = {this.handleChange} name = "localitatea" className = "input" />
                    </div>
                    
                    <div className = "Input-element" >
                        <p> Cetatenie </p>
                        <Input value = {this.state.cetatenie}  onChange = {this.handleChange} name = "cetatenie" className = "input" />
                    </div>

                    <div className = "Input-element" >
                        <p> Adresa Domiciliu </p>
                        <Input value = {this.state.domiciliu} onChange = {this.handleChange} name = "domiciliu" className = "input"/>
                    </div>

                    {/* <div className = "Input-element" >
                        <p> Telefon Fix </p>
                        <Input onChange = {this.handleChange} name = "telefonFix" className = "input"/>
                    </div> */}

                    <div className = "Input-element" >
                        <p> Telefon Mobil </p>
                        <Input value = {this.state.telefonMobil}  onChange = {this.handleChange} name = "telefonMobil" className = "input"/>
                    </div>


                    <div className = "Input-element" >
                        <p>Media la examenul de finalizare a studiilor universitare de licenta</p>
                        <InputNumber value = {this.state.medieBAC}  onChange = {this.handleChange} name = "medieBAC" className = "input" step={0.5} min = {6} max = {10}/>

                        {/* <Input onChange = {this.handleChange} name = "medieBAC" className = "input"/> */}


                        {/* <SelectPicker
                        onSelect = {this.handleSelectProba} 
                        data = {[
                            {label: "1. Baschet", value : "1. Baschet"},
                            {label: "2. Volei", value : "2. Volei"},
                            {label: "3. Handbal", value : "3. Handbal"},
                            {label: "4. Fotbal", value : "4. Fotbal"},
                            {label: "5. Gimnastica - Saritura Departat peste Capra", value : "5. Gimnastica - Saritura Departat peste Capra"},
                            {label: "6. Gimnastica - Saritura in Sprijin Ghemuit pe Lada de Gimnastica", value : "6. Gimnastica - Saritura in Sprijin Ghemuit pe Lada de Gimnastica"},
                            {label: "7. Atletism - Saritura in Lungime cu Elan", value : "7. Atletism - Saritura in Lungime cu Elan"},
                            {label: "8. Atletism - Aruncare Greutatii", value : "8. Atletism - Aruncare Greutatii"},
                            {label: "9. Atletism - Alergarea de Rezistenta", value : "9. Atletism - Alergarea de Rezistenta"},
                            {label: "10. Discipline de Combat (Doar pentru FEFS)", value : "10. Discipline de Combat (Doar pentru FEFS)"},
                        ]}
                        id = "input"  block searchable = {false} placeholder = "Alege o Optiune"/>  */}
                    </div>

                    <div className = "Input-element" >
                        <p>Media anilor de studii universitare de licenta.  </p>
                        <InputNumber value = {this.state.medieEdFizica} onChange = {this.handleChange} name = "medieEdFizica" className = "input"  step={0.5} min = {6} max = {10}/>
                    </div>

                    {/* <div className = "Input-element" >
                        <p>Medie Educatie Fizica (ultimul an)</p>
                        <Input onChange = {this.handleChange} name = "medieEdFizica" className = "input"/>
                    </div>

                    <div className = "Input-element" >
                        <p>Medie BAC Romana</p>
                        <Input onChange = {this.handleChange} name = "medieBACRO" className = "input"/>
                    </div> */}

                    <div className = "Input-element" >
                        <p> Studii Universitare Master </p>
                        <SelectPicker value = {this.state.studii_univ}  onSelect = {(value) => this.setState({studii_univ : value})} 
                        data = {[{label: "Nu am mai fost inscris la alt masterat", value : "Nu am mai fost inscris la alt masterat"}, 
                        {label: "Am fost inscris la alt masterat, dar nu am absolvit", value : "Am fost inscris la alt masterat, dar nu am absolvit"},
                        {label: "Am absolvit alt masterat", value : "Am absolvit alt masterat"}
                    ]} id = "input" block searchable = {false} placeholder = "Alege o Optiune" />
                    </div>

                    <div className = "Input-element" >
                        <Checkbox checked = {this.state.psiho} style = {{fontSize: "17px"}} onChange = {(value, checked) => this.setState({psiho : checked })}> Doresc inscrierea la modulul de Formare Psihopedagogica </Checkbox>
                    </div>

                    <div className = "Input-element" >
                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.declar} onChange = {(value, checked) => this.setState({declar : checked})}>DECLAR PE PROPRIA RASPUNDERE SI SEMNEZ PENTRU CONFORMITATE, CA CELE MENTIONATE CORESPUND REALITATII.</Checkbox>
                    </div>

                    <div className = "Input-element" >
                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.gdpr} onChange = {(value, checked) => this.setState({gdpr : checked})}>SUNT DE ACORD CU <a target = "_blank" href = "http://admitere.unefs.ro/wp-content/uploads/2022/03/FEFS-ANEXA-2a-GDPR.pdf">POLITICA DE CONFIDENTIALITATE IMPUSE DE UNEFS</a></Checkbox>
                    </div>
                    <p> In caz de probleme tehnice, va rugam sa ne contactacti la admitere_fefs@unefs.ro/admitere_fkt@unefs.ro </p>

                    {/* <Button onClick = {() =>  window.location.reload()} appearance = "ghost" > Inapoi </Button> */}

                    {/* <Button onClick = {this.sendData} appearance = "primary" > Salveaza si continua</Button> */}

                </div>
            </div>
        )
    }
}

export default Signup;