import React from 'react'
import './stylesheets/Options.scss'
import NewsElement from './components/NewsElement'
import Scrollbar from 'react-scrollbars-custom';
import axios from 'axios'
class News extends React.Component{

    state = {
        news : [
            {title : "Timpul de validare al documentelor încărcate este de 48h", date : "1-Sept-2022", content : "mail: admitere_fefs@unefs.ro/admitere_fkt@unefs.ro,  Program : Luni -Vineri 10:00- 16:00"},
            // {title : "Verificarea notelor la examenul de bacalaureat in SIIIR", date : "05-Iunie-2022", content : "Verificarea notelor la examenul de bacalaureat in SIIIR, vor putea fi verificare după data de 10.07.2022"},

            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"},
            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"},
            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"},
            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"}
        ],
        options : [],
        loading: true
    }
    componentDidMount() {

        axios.get('/api/submission/getDocumentsStatus/' + localStorage.getItem('id'))
        .then ( response => {
            console.log(response)
            this.setState({
                options : response.data.optionList,
                loading : false
            })
        })
    }
    render(){

        // if ( this.state.loading === true )
        //     return <p> Loading... </p>
        let news = this.state.options.map( (element, index)=> {

            return <p> {index + 1}. {element} </p>
        })
        return(
           
                <div className = "Options-container">
                    <Scrollbar style={{ width: "100%", height: "100%" }}>
                    <div className = "News-content">
                        
                            <h3 style = {{textAlign: "left", padding: "15px"}}> Lista optiuni </h3>
                            {news}
                    </div>
                    </Scrollbar>
                </div>
            
            
        )
    }
}

export default News;