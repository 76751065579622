import React from 'react'
import './OneDocument.scss'
import {Button, Modal, Divider, Input} from 'rsuite'
import {toast} from 'react-toastify'
import axios from 'axios'
import download from 'downloadjs'

class OneDocument extends React.Component{

    state = {

        showModal : false,
        inputValue : "",
        documentName:""
    }

    toggleModal = (name) => {

        let current = this.state.showModal;
        console.log("hi")
        this.setState({
            showModal : !current,
            documentName : name
        })
    }

    downloadDocument = async (id, file) =>{

       
        console.log(file)
        if (file.toString().includes(".pdf") === true ){
            window.open('https://pdf-viewer.admitere-unefs.ro/view?id=https://kineto.admitere-unefs.ro/api/submission/downloadFile/' + id+ "/" + file, '_blank');

        }

        else{
//  const res = await axios.get(')
        const res = await fetch("/api/submission/downloadFile/" + id+ "/" + file);
        const blob = await res.blob();
        download(blob, file);
        }

    }


    rejectDocument= () => {

        
    }

    sendComment = () => {
        
        let obj = {
            userID :this.props.id,
            comm : this.state.inputValue,
            documentName : this.props.nume
        }

        let obj2 = {
            userID :this.props.id,
            documentName : this.props.nume
        }

        axios.post('/api/admin/rejectDocument', obj2)
        .then (response => {

            toast.success("Document respins cu succes")
            console.log(response)

            axios.post('/api/admin/addComm', obj)
            .then ( response => {
                console.log(response)
                toast.success("Comentariu adaugat cu succes!")
            })
            .catch ( err => {
                console.log(err)
            })

        })
        .catch ( err => {

            toast.error("Eroare la confirmare")
        })


        
    }
    handleChange = (value) => {

        this.setState({
            inputValue : value
        })
    }

    confirmDocument = () => {

        let obj = {
            userID :this.props.id,
            documentName : this.props.nume
        }
        axios.post('/api/admin/confirmDocument', obj)
        .then (response => {

            toast.success("Document confirmat cu succes")
            console.log(response)
        })
        .catch ( err => {

            toast.error("Eroare la confirmare")
        })
    }

    missingDocument = () => {

        let obj = {
            userID :this.props.id,
            documentName : this.props.nume
        }
        axios.post('/api/admin/missingDocument', obj)
        .then (response => {

            toast.success("Document lipsa marcat cu succes")
            console.log(response)
        })
        .catch ( err => {

            toast.error("Eroare la marcare lipsa")
        })
    }

    render(){

        console.log(this.props.id)
        // let urlDownload = "/uploads-v2/" + this.props.id + "/" + this.props.nume;

        return(

            <div className = "OneDocument-container">
                <div className = "OneDocument-content">

                    <div style = {{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <p> {this.props.nume} </p>
                        <p> {this.props.status} </p>
                        <Button onClick = {() => this.downloadDocument(this.props.id, this.props.nume)} appearance = "ghost"> Descarca Document </Button>
                        <Button onClick = {this.confirmDocument} color = "green"> Confirma Document </Button>
                        <Button onClick = {this.missingDocument} color = "yellow"> Lipsa Document </Button>
                        <Button onClick = {this.toggleModal} color = "red" > Respinge Document </Button>
                        {/* <Button onClick = {this.toggleModal} color = "orange " > Adauga Comentariu </Button> */}

                    </div>
                    <div style = {{display: "flex", flexDirection: "row", marginTop: "15px"}}>
                     <Input onChange  = {this.handleChange} style = {(this.state.showModal === false)?{display:"none"}:null}/>
                     <Button onClick = {this.sendComment} appearance = "primary" style = {(this.state.showModal === false)?{display:"none"}:null}> Trimite</Button>
                    </div>
                   
                </div>
            </div>
        )
    }
}

export default OneDocument;