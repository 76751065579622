import React from 'react'
import './stylesheets/News.scss'
import NewsElement from './components/NewsElement'
import Scrollbar from 'react-scrollbars-custom';

class News extends React.Component{

    status = {
        news : [
            // {title : "Pentru Ciclul I studii universitare", date : "17-Iul-2020", content : "Inchiderea platformei de iscriere in vederea admiterii la studii, sesiunea iulie 2020 va avea loc pentru ciclul I studii universitare de licenta, vineri 18.07.2020, ora 23.59; Solutionarea dosarelor de catre comisie si reincarcarea actelor de catre candidati, in conturile care au fost create pana la data si ora inchiderii platformei, se va putea efectua pana in data de 19.07.2020, ora 23.59; Afisarea rezultatelor partiale – dupa data de 21.07.2020. Confirmarea locurilor – din momentul afisarii rezultatelor, pana la data de 27.07.2020"},
            // {title : "Pentru Ciclul II studii universitare", date : "17-Iul-2020", content : "Inchiderea platformei de înscriere în vederea admiterii la studii, sesiunea iulie 2020 va avea loc pentru ciclul II studii universitare de masterat, vineri 17.07.2020, ora 23.59. Solutionarea dosarelor de catre comisie si reincarcarea actelor de catre candidati, in conturile care au fost create pana la data si ora inchiderii platformei, se va putea efectua pana in data de 18.07.2020, ora 23.59; Afisarea rezultatelor partiale – dupa data de 20.07.2020. Confirmarea locurilor – din momentul afisarii rezultatelor, pana la data de 27.07.2020"},
            {title : "Timpul de validare al documentelor încărcate este de 48h", date : "8-Iulie-2024", content : "mail: admitere_fefs@unefs.ro/admitere_fkt@unefs.ro,  Program : Luni - Vineri 9:00- 17:00"},
            // {title : "Verificarea notelor la examenul de bacalaureat in SIIIR", date : "05-Iunie-2022", content : "Verificarea notelor la examenul de bacalaureat in SIIIR, vor putea fi verificare după data de 10.07.2022"},

            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"},
            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"},
            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"},
            // {title : "News Title", date : "12-Martie-2020", content : "New content is right here"}
        ]
    }

    render(){

        let news = this.status.news.map( element => {

            return <NewsElement title = {element.title} date = {element.date} content = {element.content}/>
        })
        return(
           
                <div className = "News-container">
                    <Scrollbar style={{ width: "100%", height: "100%" }}>
                    <div className = "News-content">
                        
                            <h3> Anunturi si Proces Inscriere </h3>




                            {/* <div className = "NewsElement-container">
                                <div className = "NewsElement-content">
                                    <p id = "title">OPIS AL DOCUMENTELOR CONTRACTUALE </p>
                                    <p id = "date"> 21-Iul-2021 </p>
                                    <p id = "content"> Documentele contractuale se vor descărca de pe site-ul UNEFS www.unefs.ro, accesând următoarele link-uri:</p>
                                    <br/>
                                    <p id = "content"><a href = "http://rest-unefs.syntract.ro/wp-content/uploads/2021/07/KMS02.pdf" target ="_blank"> PROGRAM DE STUDII - KINETOTERAPIE ȘI MOTRICITATE SPECIALĂ (KMS) (aici) </a>  </p>
    
                                
                                </div>
                            </div> */}
{/* 
                            <div className = "NewsElement-container">
                                <div className = "NewsElement-content">
                                    <p id = "title"> Contestatii </p>
                                    <p id = "date"> 23-Iul-2020 </p>
                                    <p id = "content"> Detalii despre contestatii <a href = "https://unefsb.ro/wp-content/uploads/2020/07/CONTESTATII-FEFS-LICENTA-SPM-FARA-EXAMEN-1.pdf" target = "_blank">aici</a> </p>
                                </div>
                            </div> */}

                            {/* <div className = "NewsElement-container">
                                <div className = "NewsElement-content">
                                    <p id = "title"> Rezultate Licenta provizorii inainte de contestatii </p>
                                    <p id = "date"> 23-Iul-2020 </p>
                                    <p id = "content"> Candidatii declarati admiși la Programul de studii de Educatie Fizica si Sport: <a href = "https://drive.google.com/file/d/1aFJY0_S_u4FiJ3RGsKVMPQNsDs27lyjy/view?usp=sharing" target ="_blank"> link catre rezultate </a>  </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati respinsi la Programul de studii de Educatie Fizica si Sport: <a href = "https://drive.google.com/file/d/1AFzshDJ6pB7ecyCFbacONu4LNKyI_L8R/view?usp=sharing" target ="_blank"> link catre rezultate </a>  </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati admisi pe locurile cu TAXA la Programul de studii de Educatie Fizica si Sport: <a href = "https://drive.google.com/file/d/1-aWmQW6Mdl18ZAfXNYriJhGwCrYaBObV/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii ADMISI PROVENITI PE LOCURILE DESTINATE ABSOLVENTILOR DIN SISTEMUL DE PROTECTIE SOCIALA - Programul de studii Educație Fizică si Sport: <a href = "https://drive.google.com/file/d/16fm4DcP6MJ-x-HTtspy1awvnUj-UUvAa/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati admisi pe locurile destinate cetățenilor de etnie roma - Programul de studii Educatie Fizica si Sport: <a href = "https://drive.google.com/file/d/15V6eWYtJxN6UXGQC5BFMtGry3mRShwaC/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati respinsi pe locurile destinate cetatenilor de etnie roma - Programul de studii Educatie Fizica si Sport: <a href = "https://drive.google.com/file/d/1RQtaEinuzqFnUMfQyBHirDGLPm31B85H/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati admiși pe locurile de la bugetul de stat la Programul de Studii Sport și Performanta Motrica: <a href = "https://drive.google.com/file/d/1J38hkKA8OmAll9-BvgtxhGPUtS1h2AdX/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati respinsi la Programul de Studii Sport si Performanta Motrica: <a href = "https://drive.google.com/file/d/1DyAg8QLtUcnrDdKK2GwDp4H7AKiwPvGO/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati admisi pe locurile cu TAXA la Programul de Studii Sport si Performanta Motrica: <a href = "https://drive.google.com/file/d/1sT-ONqPusHEucaXX5IuGUTSe3SCLMlFJ/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati admisi pe locurile destinate absolventilor din mediul rural la Programul de Studii Sport si Performanta Motrica: <a href = "https://drive.google.com/file/d/14Qe3AsIgSIg6XejX0tjd422Hg_cYFlHg/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii admiși la programul de studii Sport si Performanta Motrica învatamant cu frecventa redusa: <a href = "https://drive.google.com/file/d/1TtLmxs6SIvDj6IizwuT3jW-aBP3kk5y4/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati admisi pe locurile de la bugetul de stat la Programul Management in Sport: <a href = "https://drive.google.com/file/d/1dXq9n-ob2_KX2oE8U10P6u4negRvEXBx/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati admisi pe locurile cu TAXA la Programul de Studii Management in sport: <a href = "https://drive.google.com/file/d/1SgxpaoiV4BhWf7187-X2uft_vBUAMLSu/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Candidatii declarati respinsi la Programul de Studii Management in Sport: <a href = "https://drive.google.com/file/d/1nk8VLkQ_X5kwBpmvHJ1YWrh6-YMcTtH6/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Rezultate provizorii inainte de contestatii Facultatea de Kinetoterapie: <a href = "https://drive.google.com/file/d/12LAF-HOMZCSW8jNXqdOGX7XlPlRfLOJ4/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    <br/>
                                    <p id = "content"> Rezultatele candidațiilor din lista de asteptări FKT: <a href = "https://drive.google.com/file/d/119G6H2FBDdcp0TPJBpsQFwWR7dhHmxVv/view?usp=sharing" target ="_blank"> link catre rezultate </a> </p>
                                    
                                    
                                    

                                </div>
                            </div> */}

                            {/* <div className = "NewsElement-container">
                                <div className = "NewsElement-content">
                                    <p id = "title"> Pentru Ciclul I studii universitare </p>
                                    <p id = "date"> 17-Iul-2020 </p>
                                    <p id = "content"> Inchiderea platformei de inscriere in vederea admiterii la studii, sesiunea iulie 2020 va avea loc pentru ciclul I studii universitare de licenta, vineri 18.07.2020, ora 23.59; <br/> <br/> Solutionarea dosarelor de catre comisie si reincarcarea actelor de catre candidati, in conturile care au fost create pana la data si ora inchiderii platformei, se va putea efectua pana in data de 19.07.2020, ora 23.59;<br/> <br/> Afisarea rezultatelor partiale – dupa data de 21.07.2020. Confirmarea locurilor – din momentul afisarii rezultatelor, pana la data de 27.07.2020 </p>
                                </div>
                            </div>

                            <div className = "NewsElement-container">
                                <div className = "NewsElement-content">
                                    <p id = "title">Pentru Ciclul II studii universitare</p>
                                    <p id = "date"> 17-Iul-2020 </p>
                                    <p id = "content"> Inchiderea platformei de înscriere în vederea admiterii la studii, sesiunea iulie 2020 va avea loc pentru ciclul II studii universitare de masterat, vineri 17.07.2020, ora 23.59.<br/> <br/> Solutionarea dosarelor de catre comisie si reincarcarea actelor de catre candidati, in conturile care au fost create pana la data si ora inchiderii platformei, se va putea efectua pana in data de 18.07.2020, ora 23.59; <br/> <br/>Afisarea rezultatelor partiale – dupa data de 20.07.2020. Confirmarea locurilor – din momentul afisarii rezultatelor, pana la data de 27.07.2020 </p>
                                </div>
                            </div> */}

                            {news}
                    </div>
                    </Scrollbar>
                </div>
            
            
        )
    }
}

export default News;