import React from 'react'
import './stylesheets/DocumentStatus.scss'
import DocumentStatusLine from './components/DocumentStatusLine'
import {Button} from 'rsuite'
import axios from 'axios'
import Scrollbar from 'react-scrollbars-custom';


class DocumentStatus extends React.Component{

    state = {

        documents : [
            {name : "Document1", status : "Confirmed"}, 
            {name : "Document2", status : "Pending"}
        ],
        loading : true,
        content: []
    }

    componentDidMount() {

        axios.get('/api/submission/getDocumentsStatus/' + localStorage.getItem('id'))
        .then ( response => {
            console.log(response)
            this.setState({
                documents : response.data.documents,
                loading : false
            })
        })

        axios.get('/api/user/getUserInfo/' + localStorage.getItem('id'))
        .then( response => {

            console.log(response.data)
            this.setState({
                content : response.data
            })
        })
    }
    render(){

        if ( this.state.loading === true )
            return <p> Se incarca, nu inchideti pagina! </p>
        let docs = (typeof this.state.documents !== 'undefined')?this.state.documents.map( element => {
            return <DocumentStatusLine name = {element.file} status = {element.status} comment = {element.comment} />

        }):null
        return(

            <div className = "DocumentStatus-container">
                <Scrollbar style={{ width: "100%", height: "100%" }}>
                    <div className = "DocumentStatus-content">
                            <h3> Status Documente </h3>
                            {/* <p style = {{fontSize: "13px"}}> DOCUMENTELE SUNT IN CURS DE VERIFICARE - VA RUGAM ASTEPTATI RECENZIA COMISIEI OPERATIONALE DE ADMITERE! </p> */}
                            <br/><br/>
                            {docs}
                            <Button disabled = {this.state.content.can_edit === true?false: true} appearance = "primary" onClick = {this.props.moveToDocuments}> Re-incarca Documente </Button>
                    </div>
                </Scrollbar>

            </div>
        )
    }
}

export default DocumentStatus;